<template>
  <v-card>
    <v-card-title>
      {{ this.$t("erp.lang_items") }}
    </v-card-title>
    <v-divider class="ma-0" />
    <v-container class="ma-0 pa-0" fluid>
      <v-card elevation="0" outlined tile>
        <v-card-text class="ma-0 pt-4 pa-0">
          <v-card elevation="0">
            <v-card-title class="pa-0 ma-0">
              <v-row>
                <v-col class="pt-0 ma-0" cols="12">
                  <div class="card-header-tab card-header">
                    <div
                      class="card-header-title font-size-lg text-capitalize font-weight-normal"
                    >
                      <b-input-group width="100%">
                        <b-form-input
                          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :placeholder="$t('datatables.lang_search')"
                          @focus="showTouchKeyboard"
                          v-model="search"
                        />
                      </b-input-group>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0 pt-2">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    :label="$t('generic.lang_waregroup')"
                    class="mx-1"
                    outlined
                    dense
                    v-model="waregroupID"
                    @change="getDataFromApi"
                    hide-details
                    clearable
                    :items="filteredWareGroups"
                    item-value="id"
                    item-text="name"
                  />
                </v-col>
              </v-row>
              <v-divider class="ma-1" />
              <v-data-table
                :footer-props="footerProps"
                :headers="filteredDatatableHeaders"
                :items="filteredDatatableEntries"
                :loading="loading"
                sortBy="kiosk_position"
                :options.sync="pagination"
                :server-items-length="total"
                footer-props.items-per-page-text="Einträge pro Seite"
                v-model="selectedRows"
              >
                <template v-slot:body="props">
                  <draggable
                    :list="props.items"
                    tag="tbody"
                    v-bind="dragOptions"
                    @end="changePosition($event)"
                  >
                    <tr v-for="(item, index) in props.items" :key="index">
                      <td>
                        <font-awesome-icon
                          :icon="['fas', 'grip-vertical']"
                          style="color: lightgray; cursor: pointer"
                          class="page__grab-icon text-lighter handle"
                        />
                      </td>

                      <td>{{ item.name }}</td>

                      <td class="text-right">
                        {{ item.sell_price | currency }}
                      </td>

                      <td>{{ item.ware_cat }}</td>

                      <td>
                        <b-form-input
                          :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @blur="editEntry(item)"
                          @keypress.enter="editEntry(item)"
                          v-model="item.kiosk_position"
                          trim
                        />
                      </td>

                      <td>
                        <b-input-group>
                          <b-input-group-append is-text>
                            <input
                              type="checkbox"
                              v-model="item.kiosk_active"
                              @change="editEntry(item)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </td>

                      <td>
                        <b-form-input
                          :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @blur="editEntry(item)"
                          @keypress.enter="editEntry(item)"
                          v-model="item.kiosk_price"
                          type="number"
                        />
                      </td>

                      <td>
                        <b-input-group>
                          <b-input-group-append is-text>
                            <input
                              type="checkbox"
                              v-model="item.kiosk_is_featured"
                              @change="editEntry(item)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </td>

                      <td>
                        <b-form-input
                          :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @blur="editEntry(item)"
                          @keypress.enter="editEntry(item)"
                          v-model="item.kiosk_featured_name"
                        />
                      </td>

                      <td>
                        <v-menu
                          v-model="menu[item.id]"
                          :close-on-content-click="false"
                          offset-x
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                              v-model="item.kiosk_featured_color"
                            />
                          </template>

                          <v-card>
                            <v-color-picker
                              v-model="colorPicker"
                              dot-size="25"
                              hide-mode-switch
                              mode="hexa"
                            ></v-color-picker>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn text @click="closeFeaturedColor(item)">
                                {{ $t("generic.lang_cancel") }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                text
                                @click="applyFeaturedColor(item)"
                              >
                                {{ $t("generic.lang_save") }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard
              :accept="hideTouchKeyboard"
              :cancel="hideTouchKeyboard"
              :defaultKeySet="touchKeyboard.keySet"
              :input="touchKeyboard.input"
              :layout="touchKeyboard.layout"
              :options="touchKeyboard.options"
              class="internalWidthExpanded"
              id="onScreenKeyboard"
              v-if="touchKeyboard.visible"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import draggable from "vuedraggable";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

library.add(faGripVertical);

export default {
  name: "KioskProductsComponent",

  components: {
    draggable,
    "font-awesome-icon": FontAwesomeIcon,
  },

  mixins: [mixin],

  data() {
    return {
      menu: {},
      colorPicker: null,
      // 'id', 'name', 'sell_price', 'ware_cat', 'kiosk_position', 'kiosk_alias', "kiosk_active" , 'kiosk_price','kiosk_is_featured','tax_id'
      datatableHeaders: [
        {
          text: "",
          value: "crud",
        },
        {
          text: "ID",
          value: "id",
          hidden: true,
        },
        {
          text: this.$t("erp.lang_posItemName"),
          value: "name",
        },
        {
          text: this.$t("generic.lang_originalPrice"),
          value: "sell_price",
          align: "end",
        },
        {
          text: this.$t("erp.lang_nav_waregroups"),
          value: "ware_cat",
        },
        {
          text: this.$t("generic.lang_sorting"),
          value: "kiosk_position",
          align: "center",
          width: "80",
        },
        {
          text: this.$t("generic.lang_alias"),
          value: "kiosk_alias",
          width: 150,
          hidden: true,
        },
        {
          // text: this.$t("settings.lang_settings_activ"),
          text: this.$t("erp.lang_posItemName") + "/" + this.$t("generic.lang_status"),
          value: "kiosk_active",
          width: "100",
        },
        {
          text: this.$t("erp.lang_invoiceUnitPrice"),
          value: "kiosk_price",
          width: 100,
        },
        {
          text: this.$t("eventbee.lang_featured"),
          value: "kiosk_is_featured",
          width: 100,
        },
        {
          text: "tax id",
          value: "tax_id",
          hidden: true,
        },
        {
          text: this.$t("generic.lang_featured_name"),
          value: "kiosk_featured_name",
          width: 200,
        },
        {
          text: this.$t("generic.lang_featured_color"),
          value: "kiosk_featured_color",
          align: "start",
          width: 200,
        },
      ],
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: "",
      total: 0,
      pagination: {},
      footerProps: { "items-per-page-options": [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      waregroupID: null,
      groups: [],
    };
  },
  computed: {
    filteredWareGroups() {
      return this.groups.filter((waregroup) => {
        return true;
      });
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};
        let index = 0;
        this.datatableHeaders.forEach((header) => {
          if (header.value !== "crud") {
            if (header.value === "sell_price") {
              row[header.value] = Number(
                data[index].toString().replace("EUR", "").replace(",", ".")
              );
            } else {
              row[header.value] = data[index];
            }
          } else {
            return;
          }
          index++;
        });
        dataToRender.push(row);
      });
      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
  },
  methods: {
    applyFeaturedColor(item) {
      item.kiosk_featured_color = this.colorPicker.hex;
      this.editEntry(item);
      this.colorPicker = null;
      this.menu[item.id] = false;
    },
    closeFeaturedColor(item) {
      this.colorPicker = null;
      this.menu[item.id] = false;
    },
    editEntry(entry, event = null) {
      if (entry.state) {
        if (!Number(entry.lb_take_away_price)) {
          entry.lb_take_away_price = entry.price;
        }
        if (!Number(entry.lb_delivery_price)) {
          entry.lb_delivery_price = entry.price;
        }
      }

      if (entry.kiosk_active) {
        if (!Number(entry.kiosk_price)) {
          entry.kiosk_price = entry.sell_price;
        }
      }
      this.axios
        .post(ENDPOINTS.KIOSK.PRODUCTS.UPDATE, {
          id: entry.id,
          position: entry.kiosk_position,
          kiosk_alias: entry.kiosk_alias,
          kiosk_active:
            parseInt(entry.kiosk_active) === 1 || entry.kiosk_active === true,
          kiosk_price: parseFloat(entry.kiosk_price),
          kiosk_is_featured: entry.kiosk_is_featured,
          kiosk_featured_name: entry.kiosk_featured_name,
          kiosk_featured_color: entry.kiosk_featured_color,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        });
    },
    changePosition(event) {
      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex];
      if (event.oldIndex < event.newIndex) {
        if (item.id !== itemReplaced.id)
          item.position = parseInt(Number(itemReplaced.position)) + 1;
      } else if (event.oldIndex > event.newIndex) {
        if (item.id !== itemReplaced.id)
          if (parseInt(Number(itemReplaced.position)) - 1 === 0) {
            item.position = 1;
          } else {
            item.position = parseInt(Number(itemReplaced.position)) - 1;
          }
      }
      if (item.id !== itemReplaced.id) {
        this.editEntry(item);
      }
    },
    async getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart:
          this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
          (header) => header.value === this.pagination.sortBy[0]
        );
        index -= 1;
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }

      params["category"] = this.waregroupID;
      /* SPECIFY ADDITIONAL PARAMS
       * params['customerID'] = parseInt(this.$props.customerId);
       *
       * */

      this.axios
        .get(ENDPOINTS.DATATABLES.KIOSK.PRODUCTS, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(res.data.iTotalDisplayRecords);
          this.dataToShow = res.data.aaData;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
          this.dataToShow = [];
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.getDataFromApi();

    this.groups = await this.$store.dispatch("itemgroups/getItemgroups");
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
