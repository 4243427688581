<template>
  <v-container fluid>

    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-1']" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened =! opened" class="ma-0"
                         v-b-toggle.global-settings>
              <v-list-item-title :class="this.opened? 'primary--text text-wrap' : 'text-wrap'">
                {{ this.$t('generic.lang_Options') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings"
                      role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'categories'? 'primary--text' : '']"
                                   @click="handleClick('categories')"
                                   class="items">
                  {{ this.$t('erp.lang_nav_waregroups') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'products'? 'primary--text' : '']"
                                   @click="handleClick('products')"
                                   class="items">
                  {{ this.$t('erp.lang_items') }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </v-col>


      <!--content-->
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">
                      <kiosk-categories-component v-if="currentTab === 'categories'"/>
                      <kiosk-products-component v-if="currentTab === 'products'"/>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import KioskCategoriesComponent from "@/components/kiosk/products_management/KioskCategoriesComponent";
import KioskProductsComponent from "@/components/kiosk/products_management/KioskProductsComponent";

export default {
  name: "KioskProductsManagementComponent",
  components: {KioskProductsComponent, KioskCategoriesComponent},
  data() {
    return {
      currentTab: "categories",
      opened: true
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  }
}
</script>

<style scoped>

.items {
  cursor: pointer;
  user-select: none;
}
</style>
